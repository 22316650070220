<template>
  <div class="forum-image-input form-group">
    <input
      type="file"
      style="display: none"
      ref="fileInputElement"
      @change="pickFile"
      accept="image/png, image/jpeg"
    />

    <div class="position-relative">
      <button
        type="button"
        class="btn-img"
        :class="{ 'btn form-control': !url, 'btn-clean': !!url }"
        @click="openFilePicker"
      >
        <img v-if="url" :src="url" class="img-fluid" />
        <template v-else>
          <i class="fas fa-image"></i> Adicionar imagem
        </template>
      </button>

      <button
        v-if="url"
        @click.prevent="removeFile"
        class="btn btn-dark btn-sm btn-remove"
      >
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>
</template>

<script>
import ImageInput from "../ImageInput";

export default {
  extends: ImageInput,
  methods: {
    removeFile() {
      this.$emit("pick", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.forum-image-input {
  .btn-img {
    width: 100%;
    position: relative;

    img {
      border-radius: 8px;
    }
  }

  .btn-remove {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 9;
  }
}
</style>
