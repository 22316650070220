<template>
  <div class="view-forum container py-5">
    <div class="row g-4">
      <div class="col-lg-3 col-sm-6 order-lg-1">
        <h2 class="mb-0 font-serif">Fórum</h2>

        <div class="small text-secondary mt-4">
          Este é o lugar ideal para trocar ideias e debater com respeito sobre
          diversos temas relacionados às Escrituras. Participem ativamente,
          mantendo o espírito construtivo que torna nossa comunidade vibrante.
          Sejam bem-vindos!
        </div>

        <topic-categories />
      </div>

      <div class="col-lg-3 col-sm-6 order-lg-3">
        <search-form placeholder="Pesquisar tópico..." route="forum" />

        <top-topics />
      </div>

      <div class="col-lg-6 order-lg-2">
        <button class="btn btn-dark d-block w-100" @click="addDialog = true">
          <i class="fas fa-plus"></i> Criar tópico
        </button>

        <action-dispatcher
          action="forum/getTopics"
          :parameters="params"
          ref="topicsGet"
        >
          <div class="text-secondary text-center my-4">
            {{ count }} tópico{{ count === 1 ? "" : "s" }} encontrado{{
              count === 1 ? "" : "s"
            }}
          </div>

          <topic
            v-for="topic in topics"
            :key="topic.id"
            :topic="topic"
            link
            class="my-4"
            @edit="openEdit"
            @remove="openRemove"
          />
        </action-dispatcher>

        <pagination :count="count" :limit="params.limit" />
      </div>
    </div>

    <el-dialog v-model="addDialog" title="Criar tópico">
      <div v-if="showAddMessage">
        <p>
          🔍 Antes de enviar sua pergunta, por favor, pesquise se alguém já fez
          a mesma pergunta. Isso ajuda a manter o fórum organizado e garante que
          você encontre respostas rapidamente. Use a barra de pesquisa para
          verificar perguntas anteriores.
        </p>
        <p>Obrigado por sua colaboração!</p>
        <div class="d-flex gap-4 justify-content-between align-items-center">
          <button class="btn btn-outline-primary" @click="addDialog = false">
            Voltar e pesquisar
          </button>
          <button class="btn btn-primary" @click="showAddMessage = false">
            Continuar
          </button>
        </div>
      </div>
      <topics-form v-else key="a" @success="refresh(true)" />
    </el-dialog>

    <el-dialog v-model="editDialog" title="Editar tópico">
      <topics-form
        v-if="editDialog"
        key="b"
        @success="refresh(false)"
        :topic="selectedTopic"
      />
    </el-dialog>

    <confirmation
      v-model="removeDialog"
      @confirm="remove"
      text="Tem certeza que deseja remover tópico?"
      confirm-button-text="Remover"
    />
  </div>
</template>

<script>
import Topic from "@/components/forum/Topic";
import TopicsForm from "@/components/forum/TopicsForm";
import SearchForm from "@/components/SearchForm";
import TopTopics from "@/components/forum/TopTopics";
import TopicCategories from "@/components/forum/TopicCategories";
import { mapState } from "vuex";

export default {
  components: {
    TopicsForm,
    SearchForm,
    TopTopics,
    Topic,
    TopicCategories,
  },
  data() {
    return {
      showAddMessage: true,
      addDialog: false,
      selectedTopic: null,
      editDialog: false,
      removeDialog: false,
    };
  },
  computed: {
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
    params() {
      return {
        offset: (this.currentPage - 1) * 10,
        limit: 10,
        search: this.$route.query.search || "",
        category: this.$route.query.category || "",
      };
    },
    ...mapState("forum", ["topics", "count", "likedTopics", "commentedTopics"]),
  },
  methods: {
    remove() {
      this.removeDialog = false;
      this.$store
        .dispatch("forum/removeTopic", this.selectedTopic.id)
        .then(() => {
          this.$message.success("Tópico removido com sucesso");
          setTimeout(() => {
            this.refresh(false);
          }, 1999);
        })
        .catch(() => {
          this.$message.error("Erro");
        });
    },
    openEdit(topic) {
      this.editDialog = true;
      this.selectedTopic = topic;
    },
    openRemove(topic) {
      this.removeDialog = true;
      this.selectedTopic = topic;
    },
    refresh(reset = false) {
      if (reset) {
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
      }
      this.addDialog = false;
      this.editDialog = false;
      this.$refs.topicsGet.dispatch();
    },
  },
};
</script>
