<template>
  <form @submit.prevent="submit" class="forum-topics-form text-light">
    <form-group
      v-model="form.name"
      :errors="errors.name"
      id="name"
      placeholder="Título"
    />
    <form-group
      v-model="form.category_id"
      :errors="errors.category_id"
      id="category_id"
      placeholder="Categoria"
      select
      :options="categories"
    />
    <form-group
      v-model="form.description"
      :errors="errors.description"
      id="description"
      placeholder="Digite um texto..."
      required
      html
    />

    <image-input @pick="pickFile" :url="photo" />

    <div class="d-flex justify-content-end">
      <button class="btn btn-primary px-5" :disabled="loading">
        <loading :show="loading"> Publicar </loading>
      </button>
    </div>
  </form>
</template>

<script>
import ImageInput from "@/components/forum/ImageInput";
import { formFields } from "@/functions";
import { mapState } from "vuex";
import { get } from "lodash";

export default {
  components: { ImageInput },
  props: {
    topic: Object,
  },
  data() {
    const topic = this.topic || {};

    return {
      newPhoto: false,
      photo: get(this.topic, "cover"),
      ...formFields(["description", "name", "category_id"], {
        ...topic,
        category_id: get(topic, "category.id"),
      }),
    };
  },
  computed: {
    ...mapState("forum", ["categories"]),
  },
  methods: {
    pickFile(file) {
      this.newPhoto = true;
      this.photo = file;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;

      const data = { ...this.form };

      if (this.newPhoto) {
        data.cover = this.photo;
      }

      let action = "forum/addTopic";
      if (this.topic) {
        action = "forum/updateTopic";
        data.id = this.topic.id;
      }

      this.$store
        .dispatch(action, data)
        .then(() => {
          this.form.description = "";
          this.form.name = "";
          this.$message.success("Tópico salvo com sucesso");
          this.$emit("success");
        })
        .catch((error) => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.forum-topics-form {
  textarea {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: none;
    color: white;
  }
}
</style>
